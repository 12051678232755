import * as React from 'react';
import {Helmet} from 'react-helmet';

const RespawnListPage = () => {
  return (
    <main>
        <Helmet>
            <meta name="description" content="Quake For Newbies" />
            <meta name="keywords" content="quake, beginners, newbies, noobs, quake champions, quake live" />
            <meta name="author" content="burtically" />
            <meta property="og:title" content="Quake For Newbies" />
            <meta property="og:description" content="Learn to play Quake." />
            <meta property="og:image" content="https://quakenewbies.com/icons/icon-512x512.png" />
            <meta property="og:type" content="website" />
            <title>Quake Live Respawns</title>
        </Helmet>
        <ul>
            <li><a href="/respawns/aerowalk.html" target="_blank" rel="noreferrer">Aerowalk</a></li>
            <li><a href="/respawns/battleforged.html" target="_blank" rel="noreferrer">Battleforged</a></li>
            <li><a href="/respawns/bloodrun.html" target="_blank" rel="noreferrer">Bloodrun</a></li>
            <li><a href="/respawns/campgrounds.html" target="_blank" rel="noreferrer">Campgrounds</a></li>
            <li><a href="/respawns/campgroundsintel.html" target="_blank" rel="noreferrer">Campgrounds Intel</a></li>
            <li><a href="/respawns/cure.html" target="_blank" rel="noreferrer">Cure</a></li>
            <li><a href="/respawns/furiousheights.html" target="_blank" rel="noreferrer">Furious Heights</a></li>
            <li><a href="/respawns/hektik.html" target="_blank" rel="noreferrer">Hektik</a></li>
            <li><a href="/respawns/lostworld.html" target="_blank" rel="noreferrer">Lost World</a></li>
            <li><a href="/respawns/silence.html" target="_blank" rel="noreferrer">Silence</a></li>
            <li><a href="/respawns/sinister.html" target="_blank" rel="noreferrer">Sinister</a></li>
            <li><a href="/respawns/terminatria.html" target="_blank" rel="noreferrer">Terminatria</a></li>
            <li><a href="/respawns/toxicity.html" target="_blank" rel="noreferrer">Toxicity</a></li>
            <li><a href="/respawns/windsongkeep.html" target="_blank" rel="noreferrer">Windsong Keep</a></li>
        </ul>
    </main>
  )
}

export default RespawnListPage;
